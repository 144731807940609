import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbNavChangeEvent,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { interval, Observable, Subscription } from 'rxjs';
import {
  catchError,
  concatMap,
  first,
  switchMap,
  timeout,
} from 'rxjs/operators';
import { cloneDeep, has, isUndefined, merge } from 'lodash';
import { OpportunityPhasingModalConfig } from './entities/opportunity-phasing-modal-config';
import { OpportunityPhasing, PhasingTab } from './entities/opportunity-phasing';
import { RequestPayload } from '../../services/entities/request-payload';
import { PhasingEditGridConfig } from './components/phasing-edit-grid/entities/phasing-edit-grid-config';
import {
  OpportunityPhasingChanged,
  OpportunityPhasingMetricChanged,
} from './components/phasing-edit-grid/entities/phasing-edit-grid-events';
import {
  AppMessage,
  AppMessageButton,
} from '../../services/entities/app-message';
import { MessageTemplates } from '../../constants/messages.constants';
import {
  PhasingSaveModel,
  PhasingSavePeriod,
  PhasingSaveRequest,
} from './entities/opportunity-phasing-save';
import { Timeframe } from '../../services/entities/filters/timeframe';
import { DateUtils } from 'src/app/core/utils/date.utils';
import { GridColDefs } from './components/phasing-edit-grid/constants/phasing-edit-grid.constants';
import { CalcUtils } from 'src/app/core/utils/calc.utils';

import { OpportunityPhasingModalService } from '../../services/modals/opportunity-phasing-modal.service';
import { AppMessagesService } from '../../services/app-messages.service';
import { FiltersService } from '../../services/filters.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BetaMessageService } from '../../services/beta-message.service';
import { CustomNumberPipe } from '../../pipes/custom-number.pipe';
import { SpinnerComponent } from '../base/spinner/spinner.component';
import { PhasingEditGridComponent } from './components/phasing-edit-grid/phasing-edit-grid.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';
import { AppStateService } from 'src/app/shared/services/app-state.service';

@Component({
  selector: 'app-opportunity-phasing-modal',
  templateUrl: './opportunity-phasing-modal.component.html',
  styleUrls: ['./opportunity-phasing-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    FontAwesomeModule,
    SharedModule,
    NgFor,
    PhasingEditGridComponent,
    SpinnerComponent,
    DatePipe,
    CustomNumberPipe,
  ],
})
export class OpportunityPhasingModalComponent implements OnInit {
  subscription = new Subscription();

  @ViewChild('opportunityPhasingModal', { static: false })
  opportunityPhasingModal: NgbModalRef;

  loaded = true;
  currentModal: NgbModalRef;
  isOpen = false;
  config: OpportunityPhasingModalConfig;
  opportunity: OpportunityPhasing;
  currencyDescription: string;
  selectedSg: string;

  // Forms
  editGridConfig: PhasingEditGridConfig;
  phasingChanged: Array<OpportunityPhasingChanged>;
  cciFollowsRevenue: boolean;

  // Enable / Disable
  saveEnable: boolean;

  isCloud: boolean;
  isFlipFlopFlagEnabled: boolean;
  faTimes = faTimes as IconProp;

  constructor(
    private modalService: NgbModal,
    private oppPhasingModalService: OpportunityPhasingModalService,
    private filtersService: FiltersService,
    private appMessagesService: AppMessagesService,
    private betaMessage: BetaMessageService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.oppPhasingModalService.openOppPhasingModalEmitted.subscribe(
        (x: OpportunityPhasingModalConfig) => {
          this.config = x;
          this.currencyDescription =
            this.config.selectedFilters.currency.Description;
          this.getData();
          this.openModal();
        }
      )
    );

    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((appState) => {
        this.isFlipFlopFlagEnabled = appState.gcpFlipFlopFlagEnabled;
      })
    );
  }

  getData(): void {
    this.loadStarted();

    const extraParams: Record<string, any> = {
      id: this.config.opportunityId,
      dataSet: this.config.dataSet,
      groupBy: 'Opportunity,Month',
      sapCode: '-1',
    };

    this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
      this.isCloud = (betaFlag && !this.isFlipFlopFlagEnabled) || (!betaFlag && this.isFlipFlopFlagEnabled);
    });

    this.oppPhasingModalService
      .getOpportunityById(
        RequestPayload.createRequest(this.config.selectedFilters, extraParams),
        this.isCloud
      )
      .then((x: OpportunityPhasing) => {
        this.opportunity = x;
        this.selectedSg =
          this.opportunity &&
          this.opportunity.PhasingTabs &&
          this.opportunity.PhasingTabs.length > 0
            ? this.opportunity.PhasingTabs[0].ServiceGroupCode
            : '';

        this.setModalData();
        this.loadCompleted();
      });
  }

  loadStarted(): void {
    this.loaded = false;
  }

  loadCompleted(): void {
    this.loaded = true;
  }

  setModalData(): void {
    const phasingTab: PhasingTab = this.getPhasingTab();
    this.cciFollowsRevenue =
      isUndefined(phasingTab.CCIFollowsRevenue) || phasingTab.CCIFollowsRevenue;

    const phasingChangedItem: OpportunityPhasingChanged =
      this.phasingChanged?.find(
        (x: OpportunityPhasingChanged) => x.selectedSg === this.selectedSg
      );

    this.editGridConfig = new PhasingEditGridConfig({
      opportunity: this.opportunity,
      filters: this.config.filters,
      selectedFilters: this.config.selectedFilters,
      customerFilters: this.config.customerFilters,
      phasingTab,
      isReadOnly: this.config.isReadOnly,
      isAdmin: this.config.isAdmin,
      phasingMonths: phasingChangedItem?.phasingMonths,
      originalPhasingMonths: phasingChangedItem?.originalPhasingMonths,
    });
  }

  openModal(): void {
    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.opportunityPhasingModal, {
        windowClass: 'opp-phasing-modal',
        centered: true,
        size: 'lg',
        backdrop: 'static',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  getPhasingTab(sg?: string): PhasingTab {
    return this.opportunity.PhasingTabs.find(
      (x: PhasingTab) => x.ServiceGroupCode === (sg || this.selectedSg)
    );
  }

  clearData(): void {
    this.phasingChanged = null;
  }

  clearChanges(): void {
    this.phasingChanged.forEach((x: OpportunityPhasingChanged) => {
      x.changes = null;
      x.originalPhasingMonths = cloneDeep(x.phasingMonths);
    });
  }

  checkControlsAvailability(): void {
    setTimeout(() => {
      this.saveEnable =
        this.phasingChanged?.every((x: OpportunityPhasingChanged) =>
          x.isValid()
        ) && !this.config.isReadOnly;
    }, 100);
  }

  getSaveModels(): Array<PhasingSaveRequest> {
    const requests: Array<PhasingSaveRequest> = [];

    this.phasingChanged?.forEach((x: OpportunityPhasingChanged) => {
      let phasingOn: string;
      const phasingTab: PhasingTab = this.getPhasingTab(x.selectedSg);
      const revenueEdited: boolean = x.changes.find(
        (y: OpportunityPhasingMetricChanged) => y.metricName === 'NetRevenue'
      )?.hasChanged;
      const cciEdited: boolean = x.changes.find(
        (y: OpportunityPhasingMetricChanged) => y.metricName === 'Cci'
      )?.hasChanged;

      if (
        (revenueEdited && cciEdited) ||
        (revenueEdited && phasingTab.CCIFollowsRevenue)
      ) {
        phasingOn = 'Both';
      } else if (revenueEdited && !phasingTab.CCIFollowsRevenue) {
        phasingOn = 'NetRevenue';
      } else if (cciEdited) {
        phasingOn = 'Cci';
      }

      const phasingSave = new PhasingSaveModel({
        OpportunityId: this.opportunity.Id,
        ServiceGroupCode: phasingTab.ServiceGroupCode,
        PhasingSource: 'CBP',
        PhasingOn: phasingOn,
        MonthlyPhasing: [],
        CciFollowsRevenue: this.cciFollowsRevenue,
        OpportunityTimeframe: merge(new Timeframe(), {
          StartTimeId: DateUtils.dateToTimeId(
            new Date(DateUtils.normalizeDateString(phasingTab.StartDate))
          ),
          EndTimeId: 999,
          TimePeriodCode: 'Custom',
        }),
      });

      // Get Revenue and CCI rows
      const revenueRow: Record<string, any> = x.phasingMonths.rows.find(
        (x: Record<string, any>) => x.attrName === 'NetRevenue'
      );
      const cciRow: Record<string, any> = x.phasingMonths.rows.find(
        (x: Record<string, any>) => x.attrName === 'Cci'
      );

      let revenueSum = 0;
      let cciSum = 0;

      // Map period values with isOutOfPeriod=false
      revenueRow.extra.forEach((y: Record<string, any>) => {
        if (!y.isOutOfPeriod) {
          revenueSum += revenueRow[y.Id.toString()];
          cciSum += cciRow[y.Id.toString()];

          phasingSave.MonthlyPhasing.push(
            new PhasingSavePeriod({
              Id: y.Id,
              RevenueAmount: CalcUtils.roundExponencial(
                revenueRow[y.Id.toString()] || 0
              ),
              CciAmount: CalcUtils.roundExponencial(
                cciRow[y.Id.toString()] || 0
              ),
            })
          );
        }
      });

      // Get beyond and latest month columns
      const beyond: Record<string, any> = revenueRow.extra.find(
        (y: Record<string, any>) => y.Id === GridColDefs.Shared.beyondColId
      );
      const latestMonth: Record<string, any> = revenueRow.extra.find(
        (y: Record<string, any>) => y.isLatest
      );

      // Evaluates where to put allocated variance
      const latestSavePeriod: PhasingSavePeriod =
        phasingSave.MonthlyPhasing.find(
          (y: PhasingSavePeriod) =>
            (y.Id === beyond.Id && !beyond.isOutOfPeriod) ||
            (y.Id === latestMonth.Id && beyond.isOutOfPeriod)
        );
      latestSavePeriod.RevenueAmount += CalcUtils.roundExponencial(
        phasingTab.ProjectedRevenue - revenueSum
      );
      revenueRow[latestSavePeriod.Id.toString()] =
        latestSavePeriod.RevenueAmount;
      revenueRow.variance = 0;
      latestSavePeriod.CciAmount += CalcUtils.roundExponencial(
        phasingTab.ProjectedCci - cciSum
      );
      cciRow[latestSavePeriod.Id.toString()] = latestSavePeriod.CciAmount;
      cciRow.variance = 0;

      // IDs must be sequential starting from 1
      for (let i = 0; i < phasingSave.MonthlyPhasing.length; i++) {
        phasingSave.MonthlyPhasing[i].Id = i + 1;
      }

      const extraParams: Record<string, any> = {
        groupBy: 'Month,ServiceGroup',
      };

      requests.push(
        new PhasingSaveRequest({
          MasterClientId:
            this.config.selectedFilters.customer.MasterCustomerNumber,
          MasterClientName:
            this.config.selectedFilters.customer.MasterCustomerName,
          OpportunityName: this.opportunity.Name,
          OpportunityToSave: phasingSave,
          Payload: RequestPayload.createRequest(
            this.config.selectedFilters,
            extraParams
          ).Payload,
        })
      );
    });

    return requests;
  }

  getUpdateStatus(requestId: string): Promise<boolean> {
    const timeInterval = 2000;
    const timeoutDuration = 2000 * 5;
    return this.oppPhasingModalService
      .getUpdateStatus(requestId)
      .pipe(
        switchMap((x: boolean) => {
          return interval(timeInterval).pipe(
            concatMap(() =>
              this.oppPhasingModalService.getUpdateStatus(requestId)
            ),
            first((x: boolean) => {
              return x;
            })
          );
        }),
        timeout(timeoutDuration),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private errorHandler(): Observable<boolean> {
    alert('Error while saving the record');
    throw {};
  }

  onCloseModal(): void {
    if (
      this.phasingChanged?.some((x: OpportunityPhasingChanged) =>
        x.hasChanges()
      )
    ) {
      const message: AppMessage = this.saveEnable
        ? MessageTemplates.PhasingUnsavedDataToSaveConfirm
        : MessageTemplates.PhasingUnsavedDataContinueConfirm;
      message.buttons.find((x: AppMessageButton) => x.text === 'Yes').action =
        (): void => {
          if (this.saveEnable) {
            this.onSave();
          } else {
            this.appMessagesService.close(message.id);
            this.currentModal.dismiss();
            this.clearData();
          }
        };

      message.buttons.find((x: AppMessageButton) => x.text === 'No').action =
        (): void => {
          this.appMessagesService.close(message.id);

          if (this.saveEnable) {
            this.currentModal.dismiss();
            this.clearData();
          }
        };
      this.appMessagesService.show(message, { centered: true });
    } else {
      this.currentModal.dismiss();
      this.clearData();
    }
  }

  onSgTabChange(event: NgbNavChangeEvent): void {
    this.selectedSg = event.nextId;
    this.setModalData();
  }

  onCciFollowsRevenueChange(event: any): void {
    const phasingTab: PhasingTab = this.getPhasingTab();
    phasingTab.CCIFollowsRevenue = event.target.checked;
    this.setModalData();
  }

  onPhasingChanged(event: OpportunityPhasingChanged): void {
    this.phasingChanged = this.phasingChanged ?? [];

    if (
      this.phasingChanged.some(
        (x: OpportunityPhasingChanged) => x.selectedSg === event.selectedSg
      )
    ) {
      const index: number = this.phasingChanged.findIndex(
        (x: OpportunityPhasingChanged) => x.selectedSg === event.selectedSg
      );
      this.phasingChanged.splice(index, 1);
    }

    this.phasingChanged.push(event);
    this.checkControlsAvailability();
  }

  onSave(close?: boolean): void {
    if (!this.config.isReadOnly && this.saveEnable) {
      new Promise(
        (
          resolve: (x: Record<string, boolean>) => void,
          reject: () => void
        ): void => {
          if (
            this.phasingChanged?.some((x: OpportunityPhasingChanged) =>
              x.hasVariancesAllocated()
            )
          ) {
            const message: AppMessage =
              MessageTemplates.PhasingAllocateVarianceConfirm;

            message.buttons.forEach((x: AppMessageButton) => {
              x.action = (): void => {
                this.appMessagesService.close(message.id);

                resolve({
                  hasVariancesAllocated: true,
                  forceAllocate: x.text === 'Yes',
                });
              };
            });

            this.appMessagesService.show(message, { centered: true });
          } else {
            resolve({
              hasVariancesAllocated: false,
              forceAllocate: false,
            });
          }
        }
      ).then((x: Record<string, boolean>) => {
        if (
          (x.hasVariancesAllocated && x.forceAllocate) ||
          !x.hasVariancesAllocated
        ) {
          this.loadStarted();
          const requests: Array<PhasingSaveRequest> = this.getSaveModels();

          this.oppPhasingModalService
            .saveOpportunity(requests, this.isCloud)
            .then(
              (results: Array<{ isSuccess: boolean; requestId: string }>) => {
                if (this.isCloud) {
                  this.onSaveSuccessCloud(results);
                } else {
                  this.onSaveSuccessCore(results);
                }
              }
            )
            .catch((error) => {
              console.error('Save opportunity error:', error);
              this.appMessagesService.show(MessageTemplates.UnexpectedError, {
                centered: true,
              });
            })
            .finally(() => {
              this.loadCompleted();
            });
        }
      });
    }
  }

  onSaveSuccessCore(
    results: Array<{ isSuccess: boolean; requestId: string }>
  ): void {
    if (results.every((y: any) => y.isSuccess)) {
      this.clearChanges();
      this.setModalData();
      this.checkControlsAvailability();

      if (close) {
        this.currentModal.dismiss();
        this.clearData();
      }
      this.filtersService.applyFilters();
      this.appMessagesService.show(MessageTemplates.PhasingSuccessfulSaved, {
        centered: true,
      });
    } else {
      this.appMessagesService.show(MessageTemplates.UnexpectedError, {
        centered: true,
      });
    }
  }

  onSaveSuccessCloud(
    results: Array<{ isSuccess: boolean; requestId: string }>
  ): void {
    if (results.every((result) => result.isSuccess)) {
      const requestIds = results.map((result) => result.requestId);

      if (close) {
        this.currentModal.dismiss();
        this.clearData();
        this.oppPhasingModalService.closeOppPhasingModalEmitted.next(
          this.opportunity.Id.toString()
        );
      } else {
        this.clearChanges();
      }

      this.oppPhasingModalService.saveOppPhasingSuccessEmitted.next(
        requestIds.join(', ')
      );
      this.appMessagesService.show(MessageTemplates.PhasingSuccessfulSaved, {
        centered: true,
      });
      this.setModalData();
      this.checkControlsAvailability();
    } else {
      this.appMessagesService.show(MessageTemplates.UnexpectedError, {
        centered: true,
      });
    }
  }

  onSaveAndClose(): void {
    this.onSave(true);
  }
}
