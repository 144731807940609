import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import {
  NgbDropdown,
  NgbDropdownModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';

import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { FiltersManager } from '../filters/entities/filters-manager';
import { Filters } from '../../services/entities/filters/filters';
import { CustomerFilters } from '../../services/entities/filters/customer-filters';
import { MessageTemplates } from '../../constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from '../../services/entities/app-message';

import { FiltersService } from '../../services/filters.service';
import { AppMessagesService } from '../../services/app-messages.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { AppliedFiltersTooltipComponent } from './components/applied-filters-tooltip/applied-filters-tooltip.component';
import { FiltersComponent } from '../filters/filters.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared-module';
import { GcpFilterToggleComponent } from '../gcp-filter-toggle/gcp-filter-toggle.component';

@Component({
  selector: 'app-filters-picker',
  templateUrl: './filters-picker.component.html',
  styleUrls: ['./filters-picker.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    FontAwesomeModule,
    FiltersComponent,
    AppliedFiltersTooltipComponent,
    GcpFilterToggleComponent
  ],
})
export class FiltersPickerComponent implements OnInit, OnDestroy {
  @ViewChild('filtersDropdown', { static: false }) filtersDropdown: NgbDropdown;

  subscription = new Subscription();

  hasChanged = false;
  filters: Filters;
  customerFilters: CustomerFilters;
  selectedFilters: SelectedFilters;
  filtersManager: FiltersManager;
  searchText: string;

  faFilter = faFilter as IconProp;

  constructor(
    private filtersService: FiltersService,
    private appMessagesService: AppMessagesService
  ) {}

  ngOnInit(): void {
    this.getFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getFilters(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        this.filters = x;
        this.selectedFilters = y;
        this.showMessages();
      })
    );

    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          this.customerFilters = x;
        }
      )
    );

    combineLatest([
      this.filtersService.isCloudFiltersChanged,
      this.filtersService.isCloudGlobalFiltersChanged,
    ]).subscribe(([x, y]: [CustomerFilters, Filters]) => {
      this.customerFilters = x;
      this.filters = y;
    })
  }

  showMessages(): void {
    const messages: Array<AppMessage> = [];

    if (this.selectedFilters.hasAttributesSelected()) {
      messages.push(MessageTemplates.AttributeFiltersWarning);
    }

    if (this.selectedFilters.industries?.length > 0) {
      messages.push(MessageTemplates.IndustryBreakdownWarning);
    }

    if (this.selectedFilters.wmus?.length > 0) {
      messages.push(MessageTemplates.WmuBreakdownWarning);
    }

    const messagePromise = (x: AppMessage): Promise<void> => {
      return new Promise((resolve: () => void, reject: () => void): void => {
        x.buttons.forEach((y: AppMessageButton) => {
          y.action = () => {
            this.appMessagesService.close(x.id);
            resolve();
          };
        });

        this.appMessagesService.show(x, { centered: true });
      });
    };

    if (messages.length > 0) {
      messages.reduce((x: Promise<void>, y: AppMessage) => {
        return x.then(() => {
          return messagePromise(y);
        });
      }, Promise.resolve());
    }
  }
  changeFilters(newShouldPointToGcp: any): void {
    this.filtersService.updateIsCloudFiltersList(newShouldPointToGcp);
  }

  onApplyFilters(): void {
    this.filtersDropdown.close();

    const selectedFilters: SelectedFilters = this.filtersManager.applySelection(
      this.selectedFilters,
      this.filters,
      this.customerFilters
    );

    this.filtersService.updateSelectedFilters(selectedFilters);
    this.filtersService.applyFilters();
  }

  getSearchEvent(event: string): void {
    this.searchText = event;
  }

  onClearFilters(): void {
    this.filtersDropdown.close();
    this.searchText = '';
    const selectedFilters: SelectedFilters = cloneDeep(this.selectedFilters);
    selectedFilters.clear();
    this.filtersService.updateSelectedFilters(selectedFilters);
    this.filtersService.applyFilters();
  }
}
