import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { cloneDeep, remove, sum } from 'lodash';
import { BaseChartDirective } from 'ng2-charts';

import {
  CustomCurrencies,
  GroupsBy,
  SubMetrics,
} from 'src/app/shared/constants/filters.constants';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import {
  LabeledData,
  TrendChart,
  TrendLineLabeledData,
} from '../../../entities/trend-chart-data';
import { TrendChartUtils } from '../../../utils/trend-chart.utils';
import { TrendChartConfig } from './entities/trend-chart-config';
import { NgFor, NgStyle } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-trend-chart',
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SharedModule, NgFor, NgStyle],
})
export class TrendChartComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() config: TrendChartConfig;
  @ViewChild(BaseChartDirective) chartComponent: BaseChartDirective;

  trendChart: TrendChart;
  options: ChartOptions = {};
  labels: Array<BaseChartDirective['labels']> = [];
  chartType: ChartType = 'bar';
  data: ChartDataset[] = [];
  colours: Array<any> = [];
  legends: Array<any> = [];

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      this.renderChart();
    }
  }

  ngAfterViewInit(): void {
    this.generateLegend();
  }

  ngOnDestroy(): void {
    TrendChartUtils.clearTooltips();
  }

  renderChart(): void {
    this.colours = [];

    this.trendChart = new TrendChart({
      lines: TrendChartUtils.getLinesData(
        this.config.definition,
        this.config.response,
        this.config.trendFilters,
        this.config.filters.dates,
        this.config.selectedFilters
      ),
      stack: TrendChartUtils.getStackData(
        this.config.definition,
        this.config.response,
        this.config.trendFilters,
        this.config.filters.dates
      ),
    });

    this.validateData();

    this.options = TrendChartUtils.getChartOptions(
      this.trendChart,
      this.config
    );
    this.labels = this.trendChart.stack.periods;

    // Lines
    const baseLineConfig: ChartDataset = TrendChartUtils.getLinesOptions();

    const linesDataSets: Array<ChartDataset> = this.trendChart.lines.items.map(
      (x: TrendLineLabeledData) => {
        const lineConfig: ChartDataset = cloneDeep(baseLineConfig);
        lineConfig.data = x.data;
        lineConfig.label = x.label;
        lineConfig.borderDash = x.borderDash;
        this.colours.push({ backgroundColor: lineConfig.backgroundColor });
        return lineConfig as ChartDataset;
      }
    );

    // Stack
    const stackData: Array<LabeledData> = this.config.trendFilters.isPercentage
      ? [this.trendChart.stack.totals]
      : this.trendChart.stack.items;

    this.colours.push(
      ...TrendChartUtils.getColoursForStackBars(
        stackData,
        this.config.trendFilters
      )
    );

    const stackDataSets: Array<ChartDataset> = stackData.map(
      (x: LabeledData) => {
        return { label: x.label, data: x.data } as ChartDataset;
      }
    );

    this.data = [...linesDataSets, ...stackDataSets];
  }

  validateData(): void {
    const planTotal: number = sum(
      this.trendChart.lines.items.find(
        (x: TrendLineLabeledData) => x.label === SubMetrics.Plan.toUpperCase()
      )?.data
    );

    const projectionTotal: number = sum(
      this.trendChart.lines.items.find((x: TrendLineLabeledData) =>
        x.label.includes(SubMetrics.Projection)
      )?.data
    );

    const removePlan: boolean =
      this.config.trendFilters.groupBy.value === GroupsBy.Industry.id ||
      (this.config.selectedFilters.currency.Id ==
        CustomCurrencies.FxAdjusted.id &&
        this.config.definition.type === MetricType.Cci) ||
      this.config.selectedFilters.hasAttributesSelected() ||
      planTotal === 0;

    const removeProjection: boolean =
      [CustomCurrencies.FxAdjusted.id, CustomCurrencies.Constant.id].includes(
        this.config.selectedFilters.currency.Id
      ) || projectionTotal === 0;

    remove(
      this.trendChart.lines.items,
      (x: TrendLineLabeledData) =>
        (x.label === SubMetrics.Plan.toUpperCase() && removePlan) ||
        (x.label.includes(SubMetrics.Projection) && removeProjection)
    );
  }

  generateLegend(): void {
    if (this.trendChart.stack.items.length > 0) {
      this.legends = this.chartComponent.chart.generateLegend() as Array<any>;
      this.changeDetector.detectChanges();
    }
  }
}
