import { Component } from '@angular/core';
import { isArray } from 'lodash';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgFor } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-link-cell',
  templateUrl: './link-cell.component.html',
  styleUrls: ['./link-cell.component.scss'],
  standalone: true,
  imports: [NgIf, SharedModule, NgFor, FontAwesomeModule],
})
export class LinkCellComponent {
  params: any;
  data: Record<string, any> = {
    cellClass: '',
    hasLink: false,
    hasTooltip: true,
    tooltip: {
      placement: 'top',
      value: '',
    },
    hasIcons: false,
    icons: [],
  };

  agInit(params: any): void {
    this.params = params;

    if (this.params) {
      if (params.icons && isArray(params.icons)) {
        this.data.hasIcons = true;
        this.data.icons = params.icons;
      }

      if (params.cellClass) {
        this.data.cellClass = params.cellClass;
      }

      if (params.colDef.field) {
        if (
          params.data[params.colDef.field] &&
          params.data[params.colDef.field]
        ) {
          this.data.hasLink = true;
          this.data.link = {
            url: params.data[params.colDef.field],
            target: '_blank',
            value: params.value,
          };
        }
      }
      if (!params.value) {
        params.column.colId.includes('Id') ? params.value = 'TOTALS' : params.value = '-'
      }
    }
  }
}
