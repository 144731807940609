import { Component } from '@angular/core';
import { isEmpty } from 'lodash';

import {
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-real-number-filter',
  templateUrl: './real-number-filter.component.html',
  styleUrls: ['./real-number-filter.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf],
})
export class RealNumberFilterComponent implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: any;

  firstNum: string;
  secondNum: string;
  selectedFilter = 'equals';

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return this.selectedFilter === 'inRange'
      ? !isEmpty(this.firstNum) && !isEmpty(this.secondNum)
      : !isEmpty(this.firstNum);
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value: number = this.valueGetter(params.node);
    const firstNum = Number(this.firstNum);
    const secondNum = Number(this.secondNum);

    if (value) {
      switch (this.selectedFilter) {
        case 'equals':
          return (
            Math.round(value) - firstNum === 0 ||
            parseFloat(value.toFixed(1)) - firstNum === 0 ||
            value - firstNum === 0
          );
        case 'notEqual':
          return !(
            Math.round(value) - firstNum === 0 ||
            parseFloat(value.toFixed(1)) - firstNum === 0 ||
            value - firstNum === 0
          );
        case 'lessThan':
          return (
            Math.round(value) < firstNum ||
            parseFloat(value.toFixed(1)) < firstNum ||
            value < firstNum
          );
        case 'lessThanOrEqual':
          return (
            Math.round(value) <= firstNum ||
            parseFloat(value.toFixed(1)) <= firstNum ||
            value <= firstNum
          );
        case 'greaterThan':
          return (
            Math.round(value) > firstNum ||
            parseFloat(value.toFixed(1)) > firstNum ||
            value > firstNum
          );
        case 'greaterThanOrEqual':
          return (
            Math.round(value) >= firstNum ||
            parseFloat(value.toFixed(1)) > firstNum ||
            value > firstNum
          );
        case 'inRange':
          return (
            (firstNum <= Math.round(value) ||
              firstNum <= parseFloat(value.toFixed(1)) ||
              firstNum <= value) &&
            (Math.round(value) <= secondNum ||
              parseFloat(value.toFixed(1)) <= secondNum ||
              value <= secondNum)
          );
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  getModel(): any {
    return { firstValue: this.firstNum, secondValue: this.secondNum };
  }

  setModel(model: any): void {
    this.firstNum = model && model.firstValue ? model.firstValue : undefined;
    this.secondNum = model && model.secondValue ? model.secondValue : undefined;
  }

  onFirstNumberChanged(newValue: any): void {
    if (this.firstNum !== newValue) {
      this.firstNum = newValue;

      if (
        this.selectedFilter !== 'inRange' ||
        (this.selectedFilter === 'inRange' && this.secondNum !== undefined)
      ) {
        this.params.filterChangedCallback();
      }
    }
  }

  onSecondNumberChanged(newValue: any): void {
    if (this.secondNum !== newValue) {
      this.secondNum = newValue;
      if (this.selectedFilter === 'inRange' && this.firstNum !== undefined) {
        this.params.filterChangedCallback();
      }
    }
  }

  onFilterChanged(): void {
    this.params.filterChangedCallback();
  }
}
