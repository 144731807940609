import { sumBy } from 'lodash';

import {
  MetricsOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid-community';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SortColum } from 'src/app/shared/components/base/grid/entities/grid-config';
import { WinsResponse } from 'src/app/shared/services/entities/grids/wins-response';

export const ComponentNames = {
  wins: 'wins',
  metrics: 'metricName',
  targets: 'target',
  export: 'export',
  expand: 'expand',
  compress: 'compress',
  noActivity: 'noActivity',
  msaIndicator: 'msaIndicator',
};

export const GridFilters = {
  Metrics: [
    new TextValuePair({
      text: MetricsOptions.WinsThisMonth,
      value: MetricsOptions.WinsThisMonth,
    }),
    new TextValuePair({
      text: MetricsOptions.Wins,
      value: MetricsOptions.Wins,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};

export const GridColDefs = {
  getColDefs: (
    toolbarConfig: GridToolbarConfig,
    filters: Filters,
    selectedFilters: SelectedFilters
  ): Array<any> => {
    const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.metrics
    );

    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );

    return [
      {
        colId: 'opportunity',
        headerName:
          metricsDropdown.selected.value === MetricsOptions.WinsThisMonth
            ? 'OPPORTUNITY (Wins this Month)'
            : 'OPPORTUNITY (Wins)',
        field: 'opportunityLink',
        type: 'linkColumn',
        valueGetter: (params: any) => {
          return params.data.id ? params.data.id : undefined;
        },
        minWidth: 130,
        width: 150,
        sort: GridColDefs.Shared.paramSort('opportunity'),
        pinnedRowCellRendererParams: { forceTotals: true },
        pinned: true,
      },
      {
        colId: 'opportunityName',
        headerName: 'OPPORTUNITY NAME',
        field: 'opportunityLink',
        type: 'linkColumn',
        valueGetter: (params: any) => {
          return params.data.name ? params.data.name : undefined;
        },
        minWidth: 230,
        sort: GridColDefs.Shared.paramSort('opportunity'),
        pinned: true,
        comparator: (valueA: string, valueB: string): number => {
          return valueA.localeCompare(valueB, 'en-US', { numeric: true });
        },
      },
      {
        colId: 'director',
        headerName: 'DIRECTOR',
        field: 'director',
        type: 'textColumn',
        minWidth: 100,
        pinned: true,
        sort: GridColDefs.Shared.paramSort('director'),
      },
      {
        colId: 'statusSinceDate',
        headerName: 'STATUS SINCE DATE',
        field: 'statusSinceDate',
        type: 'dateColumn',
        pinned: false,
        sort: GridColDefs.Shared.paramSort('statusSinceDate'),
        minWidth: 150,
      },
      {
        colId: 'contract',
        headerName: 'CONTRACT',
        field: 'contractId',
        type: 'textColumn',
        pinned: false,
        sort: GridColDefs.Shared.paramSort('contract'),
        minWidth: 120,
      },
      {
        colId: 'startDate',
        headerName: 'START DATE',
        field: 'startDate',
        type: 'dateColumn',
        pinned: false,
        sort: GridColDefs.Shared.paramSort('startDate'),
        minWidth: 100,
        valueGetter: (params: any) => {
          return (
            params.data.consultingStartDate || params.data.outsourcingStartDate
          );
        },
      },
      {
        colId: 'totalSales',
        headerName: 'TOTAL SALES',
        field: 'wonRevenueAmount',
        type: 'numberColumn',
        aggFunc: 'sum',
        sort: GridColDefs.Shared.paramSort('totalSales'),
        pinned: false,
        minWidth: 100,
      },
      {
        colId: 'cciPercentage',
        headerName: 'CCI%',
        field: 'cCIPercentage',
        type: 'percentColumn',
        pinned: false,
        sort: GridColDefs.Shared.paramSort('cciPercentage'),
        aggFunc:
          targetsDropdown.selected.value === ViewOptions.CompareProjection &&
          metricsDropdown.selected.value === MetricsOptions.WinsThisMonth
            ? GridColDefs.Shared.sumCciCompare
            : GridColDefs.Shared.sumCci,
        minWidth: 80,
      },
      {
        colId: 'cciDollars',
        headerName: 'CCI$',
        field: 'wonCciAmount',
        type: 'numberColumn',
        pinned: false,
        sort: GridColDefs.Shared.paramSort('cciDollars'),
        aggFunc: 'sum',
        minWidth: 80,
      },
    ];
  },
  Shared: {
    paramSort: (colId: string): string => {
      const sortColumsConfig: SortColum[] = [
        { colId: 'totalSales', sort: 'desc' },
      ];
      const sortColum = sortColumsConfig.find(
        (x: SortColum) => x.colId === colId
      );
      return sortColum ? sortColum.sort : '';
    },
    sumCci: (values: Array<WinsResponse>): number => {
      let totalCciPercentage = 0;
      const totalRevenueAmount: number = sumBy(values, 'wonRevenueAmount');
      const totalCciAmount: number = sumBy(values, 'wonCciAmount');

      if (typeof (totalCciAmount || totalRevenueAmount) === 'number') {
        totalCciPercentage = (totalCciAmount / totalRevenueAmount || 0) * 100;
      }

      return isFinite(totalCciPercentage) ? totalCciPercentage : 0;
    },
    sumCciCompare: (values: Array<WinsResponse>): number => {
      let totalCciPercentage = 0;
      const totalRevenueAmountCurrent: number = sumBy(
        values,
        'wonRevenueAmountCurrent'
      );
      const totalRevenueAmountTarget: number = sumBy(
        values,
        'wonRevenueAmountTarget'
      );
      const totalCCIAmountCurrent: number = sumBy(
        values,
        'wonCciAmountCurrent'
      );
      const totalCCIAmountTarget: number = sumBy(values, 'wonCciAmountTarget');

      if (
        typeof (
          totalRevenueAmountCurrent ||
          totalRevenueAmountTarget ||
          totalCCIAmountCurrent ||
          totalCCIAmountTarget
        ) === 'number'
      ) {
        let currentPercentage: number =
          totalCCIAmountCurrent / totalRevenueAmountCurrent || 0;
        let targetPercentage: number =
          totalCCIAmountTarget / totalRevenueAmountTarget || 0;
        currentPercentage = isFinite(currentPercentage) ? currentPercentage : 0;
        targetPercentage = isFinite(targetPercentage) ? targetPercentage : 0;

        totalCciPercentage = (currentPercentage - targetPercentage) * 100;
      }

      return totalCciPercentage;
    },
  },
  getExtraGridOptions: (): GridOptions => {
    return {
      defaultExportParams: {
        sheetName: 'Opportunities',
        fileName: 'CBP Opportunities',
      } as CsvExportParams,
    } as GridOptions;
  },
};
