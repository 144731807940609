import { cloneDeep, uniqBy } from 'lodash';
import moment from 'moment';
import {
  GridOptions,
  ExcelStyle,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  ColDef,
} from 'ag-grid-community';

import { DefaultColumnTypes } from '../entities/grid-config';
import { GridLoadingComponent } from '../components/cell-renderers/grid-loading/grid-loading.component';
import { NumericEditorComponent } from '../components/cell-renderers/numeric-editor/numeric-editor.component';
import { TotalsRowComponent } from '../components/cell-renderers/totals-row/totals-row.component';
import { StringCellComponent } from '../components/cell-renderers/string-cell/string-cell.component';
import { NumericCellComponent } from '../components/cell-renderers/numeric-cell/numeric-cell.component';
import { PercentageCellComponent } from '../components/cell-renderers/percentage-cell/percentage-cell.component';
import { DateCellComponent } from '../components/cell-renderers/date-cell/date-cell.component';
import { IconCellComponent } from '../components/cell-renderers/icon-cell/icon-cell.component';
import { LinkCellComponent } from '../components/cell-renderers/link-cell/link-cell.component';
import { GridCellItemAlign } from 'src/app/shared/constants/grid.constants';
import { RealNumberFilterComponent } from '../components/cell-renderers/real-number-filter/real-number-filter.component';
import { TimeframeUtils } from '../../../timeframe/utils/timeframe.utils';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { TimeframeItem } from '../../../timeframe/entities/timeframe';

export class GridUtils {
  static getDefaultOptions(): GridOptions {
    return cloneDeep(<GridOptions>{
      animateRows: true,
      columnTypes: this.getColumnTypes(),
      enableColResize: true,
      enableFilter: true,
      enableRangeSelection: true,
      enableSorting: true,
      excelStyles: this.getExcelStyles(),
      frameworkComponents: {
        loadingOverlay: GridLoadingComponent,
        numericEditor: NumericEditorComponent,
        rowRendererTotalsComponent: TotalsRowComponent,
        stringCellRenderer: StringCellComponent,
        numberCellRenderer: NumericCellComponent,
        percentCellRenderer: PercentageCellComponent,
        dateCellRenderer: DateCellComponent,
        iconCellRenderer: IconCellComponent,
        linkCellRenderer: LinkCellComponent,
      },
      getContextMenuItems: function getContextMenuItems(
        params: GetContextMenuItemsParams
      ) {
        return [
          'copyWithHeaders',
          // {
          //   name: 'Excel Export',
          //   action: () => {
          //     params.api.exportDataAsExcel({
          //       // processHeaderCallback: GridUtils.processHeaderCallback,
          //       // processCellCallback: GridUtils.processCellCallback,
          //       columnGroups: true,
          //     });
          //   },
          // },
        ];
      },
      getMainMenuItems: (params: GetMainMenuItemsParams) => {
        return [
          'pinSubMenu',
          'separator',
          'autoSizeThis',
          'autoSizeAll',
          'separator',
          'resetColumns',
        ];
      },
      getRowHeight: (params: any) => {
        return params.node.rowPinned ? 45 : 35;
      },
      headerHeight: 50,
      icons: {
        sortAscending: '<i class="material-icons">arrow_upward</i>',
        sortDescending: '<i class="material-icons">arrow_downward</i>',
        menu: '<i class="material-icons small-icon">filter_list</i>',
        filter: '<i class="material-icons small-icon">search</i>',
        columns: '<i class="material-icons small-icon">view_column</i>',
      },
      loadingOverlayComponent: 'loadingOverlay',
      // processCellForClipboard: GridUtils.processCellForClipboard,
      // processCellFromClipboard: GridUtils.processCellFromClipboard,
      singleClickEdit: true,
      sortingOrder: ['desc', 'asc', null],
      stopEditingWhenGridLosesFocus: true,
      suppressCellSelection: false,
      suppressMenuHide: true,
      suppressMovableColumns: true,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      toolPanelSuppressSideButtons: true,
    });
  }

  static getColumnTypes(): DefaultColumnTypes {
    return {
      dateColumn: {
        cellRendererFramework: DateCellComponent,
        cellRendererParams: {
          align: GridCellItemAlign.Right,
        },
        pinnedRowCellRendererFramework: TotalsRowComponent,
        filterParams: GridUtils.dateFilter,
        filter: 'agDateColumnFilter',
        menuTabs: ['filterMenuTab'],
        getQuickFilterText: () => '',
        comparator: GridUtils.dateFilter.comparator,
      },
      iconColumn: {
        cellRendererFramework: IconCellComponent,
        pinnedRowCellRendererFramework: TotalsRowComponent,
        filter: 'agSetColumnFilter',
        menuTabs: ['filterMenuTab'],
        getQuickFilterText: () => '',
      },
      linkColumn: {
        cellRendererFramework: LinkCellComponent,
        pinnedRowCellRendererFramework: TotalsRowComponent,
        filter: 'agSetColumnFilter',
        menuTabs: ['filterMenuTab'],
      },
      numberColumn: {
        cellRendererFramework: NumericCellComponent,
        pinnedRowCellRendererFramework: TotalsRowComponent,
        pinnedRowCellRendererParams: {
          align: GridCellItemAlign.Right,
        },
        filter: '',
        filterFramework: RealNumberFilterComponent,
        menuTabs: ['filterMenuTab'],
        cellClass: 'excelNumberFormat',
        getQuickFilterText: () => '',
      },
      percentColumn: {
        cellRendererFramework: PercentageCellComponent,
        cellRendererParams: {
          round: false,
        },
        pinnedRowCellRendererFramework: TotalsRowComponent,
        pinnedRowCellRendererParams: {
          align: GridCellItemAlign.Right,
          round: false,
        },
        filter: '',
        filterFramework: RealNumberFilterComponent,
        menuTabs: ['filterMenuTab'],
        cellClass: 'excelPercentFormat',
        getQuickFilterText: () => '',
      },
      textColumn: {
        cellRendererFramework: StringCellComponent,
        cellRendererParams: {
          align: GridCellItemAlign.Left,
        },
        pinnedRowCellRendererFramework: TotalsRowComponent,
        filter: 'agSetColumnFilter',
        menuTabs: ['filterMenuTab'],
      },
      shortColumn: {
        minWidth: 80,
      },
      mediumColumn: {
        minWidth: 100,
      },
      wideColumn: {
        minWidth: 150,
      },
    };
  }

  static getExcelStyles(): Array<ExcelStyle> {
    return [
      {
        id: 'excelNumberFormat',
        numberFormat: { format: '#,##0;(#,##0);0' },
      },
      {
        id: 'excelPercentFormat',
        numberFormat: { format: '#,##0.0;(#,##0.0);0' },
      },
    ];
  }

  static dateFilter: Record<string, any> = {
    comparator: (date1: string, date2: string) => {
      const timestamp1: number = date1
        ? Number(moment(date1).format('YYYYMMDD'))
        : 0;
      const timestamp2: number = date2
        ? Number(moment(date2).format('YYYYMMDD'))
        : 0;
      return timestamp2 - timestamp1;
    },
    browserDatePicker: true,
    suppressAndOrCondition: true,
    inRangeInclusive: true,
  };

  static getMonthlyHeaders(
    timeframe: TimeframeItem,
    dates: Array<MmbDate>
  ): Array<ColDef> {
    const timeframes: Array<MmbDate> = TimeframeUtils.getTimeframesRange(
      timeframe,
      dates
    );
    const headerTimeframes: Array<ColDef> = timeframes.map(
      (x: MmbDate, index: number) => GridUtils.getMonthHeader(x, index)
    );
    return headerTimeframes;
  }

  static getQuarterlyHeaders(
    timeframe: TimeframeItem,
    dates: Array<MmbDate>,
    isCustomTimeframe?: boolean
  ): Array<ColDef> {
    const timeframes: Array<MmbDate> = TimeframeUtils.getTimeframesRange(
      timeframe,
      dates
    );

    const headerTimeframes: Array<ColDef> = uniqBy(
      timeframes.map((x: MmbDate, index: number) => {
        return this.getQuarterHeader(x, index, isCustomTimeframe);
      }),
      'headerName'
    );

    return headerTimeframes;
  }

  private static getMonthHeader(timeframe: MmbDate, index: number): ColDef {
    const months: Array<string> = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];

    const colDef: ColDef = {
      headerName: months[timeframe.MonthNbr - 1],
      colId: timeframe.Id.toString(),
      field: timeframe.Id.toString(),
      pinned: false,
      minWidth: 100,
    };

    if (index === 0 || timeframe.MonthNbr === 1) {
      colDef.headerName = `${colDef.headerName}${GridUtils.getHeaderYear(
        timeframe.CalendarYearNbr
      )}`;
    }

    return colDef;
  }

  private static getQuarterHeader(
    timeframe: MmbDate,
    index: number,
    isCustomTimeframe?: boolean
  ): ColDef {
    const quarters: Array<string> = ['Q1', 'Q2', 'Q3', 'Q4'];
    const indexYearRequired: Array<number> = [0, 1, 2];

    const colDef: ColDef = {
      headerName: <string>quarters[timeframe.FiscalQuarterNbr - 1],
      field: <string>quarters[timeframe.FiscalQuarterNbr - 1].toLowerCase(),
      pinned: false,
    };

    if (
      timeframe.FiscalQuarterNbr === 1 &&
      !indexYearRequired.includes(index)
    ) {
      colDef.headerName = `${colDef.headerName}${GridUtils.getHeaderYear(
        timeframe.FiscalYearNbr
      )}`;
    }

    if (isCustomTimeframe) {
      colDef.headerName =
        <string>quarters[timeframe.FiscalQuarterNbr - 1] +
        "'" +
        timeframe.FiscalYearNbr.toString().slice(-2);
      colDef.field = colDef.headerName.toLowerCase();
    }

    return colDef;
  }

  private static getHeaderYear(year: number): string {
    return ` '${(year % 100).toString()}`;
  }
}
