<div id="chart-{{config?.id}}" class="chart">
    <canvas baseChart [width]="config?.width" [height]="config?.height" datasets="data" labels="labels"
        options="options" chartType="chartType" colors="colours">
    </canvas>
    <div class="chart-legends ml-2">
        <div *ngFor="let l of legends" class="legend-item mb-1">
            <div [ngStyle]="{'background-color': l.fillStyle}" class="legend-key {{l.className}}"></div>
            <p class="legend-label" title="{{l.text}}">{{l.text}}</p>
        </div>
    </div>
</div>