import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { isUndefined, isNull } from 'lodash';
import { IPageInfo, VirtualScrollerModule } from 'ngx-virtual-scroller';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { SharedModule } from 'src/app/shared-module';

@Component({
  selector: 'app-virtual-list-dropdown',
  templateUrl: './virtual-list-dropdown.component.html',
  styleUrls: ['./virtual-list-dropdown.component.scss'],
  standalone: true,
  imports: [SharedModule, NgClass, NgFor, NgIf, FontAwesomeModule],
})
export class VirtualListDropdownComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() items = new Array<any>();
  @Input() displayAttr = '';
  @Input() valueAttr = '';
  @Input() selectedItem: any;
  @Input() refresh: Observable<void>;
  @Output() itemSelectionChangeEvent = new EventEmitter<any>();

  subscription = new Subscription();
  public scrollItems = new Array<any>();
  readonly bufferSize: number = 50;
  indices: IPageInfo;
  buffer = new Array<any>();

  faCheckCircle = faCheckCircle as IconProp;

  ngOnInit(): void {
    if (this.refresh) {
      this.subscription.add(
        this.refresh.subscribe(() => {
          this.buffer = this.fetchNextChunk(0, this.bufferSize);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      this.buffer = this.fetchNextChunk(0, this.bufferSize);
    }
  }

  fetchMore(event: IPageInfo) {
    this.indices = event;
    if (!this.buffer) {
      this.buffer = [];
    }

    if (
      event.endIndex === this.buffer.length - 1 &&
      this.items.length !== event.endIndex
    ) {
      const chunk = this.fetchNextChunk(this.buffer.length, this.bufferSize);
      if (!isNull(chunk) && !isUndefined(chunk)) {
        this.buffer = this.buffer.concat(chunk);
      }
    }
  }

  fetchNextChunk(skip: number, limit: number): any[] {
    if (!this.items) {
      this.items = [];
    }

    if (skip >= this.items.length) {
      return null;
    }

    return this.items.slice(skip, skip + limit);
  }

  getStyleName(): string {
    if (this.items.length < 10) {
      return `items-${this.items.length}`.trim();
    }

    return 'all-items';
  }

  onItemSelection(item: any) {
    this.selectedItem = item;
    this.itemSelectionChangeEvent.emit(item);
  }
}
