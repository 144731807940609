import { cloneDeep, sumBy } from 'lodash';

import { GroupsBy, Periods } from 'src/app/shared/constants/filters.constants';
import {
  CustomOptions,
  MetricsOptions,
  MetricsValues,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid-community';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarMultipleDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { GridUtils } from 'src/app/shared/components/base/grid/utils/grid.utils';
import { Filters } from 'src/app/shared/services/entities/filters/filters';

export const ComponentNames = {
  analytics: 'analytics',
  metrics: 'metrics',
  periods: 'periods',
  group1: 'group1',
  group2: 'group2',
  group3: 'group3',
  export: 'export',
  dataset1: 'dataset1',
  dataset2: 'dataset2',
  apply: 'apply',
};

export const RecordTypes = {
  Contract: 'Contract',
  Wins: 'Opportunity (Wins this Month)',
  Opportunity: 'Opportunity',
  Speculative: 'Speculative',
};

export const GridFilters = {
  Metrics: [
    new TextValuePair({
      text: MetricsOptions.Sales,
      value: MetricsValues.Sales,
    }),
    new TextValuePair({
      text: MetricsOptions.Revenue,
      value: MetricsValues.Revenue,
    }),
    new TextValuePair({
      text: `Delivered ${MetricsOptions.Cci}`,
      value: MetricsValues.Cci,
    }),
    new TextValuePair({
      text: `Delivered ${MetricsOptions.CciPercentage}`,
      value: MetricsValues.CciPercentage,
    }),
    new TextValuePair({
      text: MetricsOptions.WonCci,
      value: MetricsValues.WonCci,
    }),
  ],
  Groups: [
    new TextValuePair({
      text: GroupsBy.Composition.text,
      value: GroupsBy.Composition.id,
    }),
    new TextValuePair({
      text: GroupsBy.ServiceGroup.text,
      value: GroupsBy.ServiceGroup.id,
    }),
    new TextValuePair({
      text: GroupsBy.ServiceDimension.text,
      value: GroupsBy.ServiceDimension.id,
    }),
    new TextValuePair({
      text: GroupsBy.ClientGroup.text,
      value: GroupsBy.ClientGroup.id,
    }),
    new TextValuePair({
      text: GroupsBy.Market.text,
      value: GroupsBy.Market.id,
    }),
    new TextValuePair({
      text: GroupsBy.Location.text,
      value: GroupsBy.Location.id,
    }),
    new TextValuePair({
      text: GroupsBy.WMU.text,
      value: GroupsBy.WMU.id,
    }),
    new TextValuePair({
      text: GroupsBy.Industry.text,
      value: GroupsBy.Industry.id,
    }),
    new TextValuePair({
      text: GroupsBy.FinancialCustomer.text,
      value: GroupsBy.FinancialCustomer.id,
    }),
    new TextValuePair({
      text: GroupsBy.ByRecord.text,
      value: GroupsBy.ByRecord.id,
    }),
    new TextValuePair({
      text: CustomOptions.None,
      value: CustomOptions.None,
    }),
  ],
  Periods: [
    new TextValuePair({
      text: Periods.Quarter.text,
      value: Periods.Quarter.id,
    }),
    new TextValuePair({
      text: Periods.Month.text,
      value: Periods.Month.id,
    }),
    new TextValuePair({
      text: CustomOptions.None,
      value: CustomOptions.None,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: selectedFilters.plan.getText(),
        value: ViewOptions.Plan,
      }),
      new TextValuePair({
        text: CustomOptions.None,
        value: CustomOptions.None.toLowerCase(),
      }),
    ];
  },
};

export const GridColDefs = {
  getColDefs: (
    toolbarConfig: GridToolbarConfig,
    filters: Filters,
    selectedFilters: SelectedFilters
  ): Array<ColDef> => {
    const periodColumnDefs: Array<ColDef> =
      GridColDefs.Shared.getPeriodsGridColDefs(
        toolbarConfig,
        filters,
        selectedFilters
      );
    return GridColDefs.Shared.getAllGridColDefs(
      periodColumnDefs,
      toolbarConfig
    );
  },
  Shared: {
    getPeriodsGridColDefs: (
      toolbarConfig: GridToolbarConfig,
      filters: Filters,
      selectedFilters: SelectedFilters
    ): Array<ColDef> => {
      let columnDefs: Array<ColDef> = [];

      const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.periods
      );

      const isCustomTimeframe: boolean =
        selectedFilters.timeframe.title === 'Custom';

      const timeframe: TimeframeItem = cloneDeep(selectedFilters.timeframe);
      // timeframe.end = customTimeframe ? timeframe.end : timeframe.start + 11;

      if (periodsDropdown.selected.value === Periods.Quarter.id) {
        columnDefs = columnDefs.concat(
          GridColDefs.Shared.addCustomRendererConfigs(
            GridUtils.getQuarterlyHeaders(
              timeframe,
              filters.dates,
              isCustomTimeframe
            ),
            true
          )
        );
      } else if (periodsDropdown.selected.value === Periods.Month.id) {
        columnDefs = columnDefs.concat(
          GridColDefs.Shared.addCustomRendererConfigs(
            GridUtils.getMonthlyHeaders(timeframe, filters.dates),
            false
          )
        );
      }

      columnDefs.push({
        headerName: 'TOTAL',
        field: 'total',
        pinned: false,
        minWidth: 75,
        type: 'numberColumn',
        // filterValueGetter: (params: any) => {
        //   if (params.data.sales) {
        //     return Math.round(params.data.sales.total);
        //   }

        //   return 0;
        // },
      });

      return columnDefs;
    },
    getAllGridColDefs: (
      periodColumnDefs: Array<ColDef>,
      toolbarConfig: GridToolbarConfig
    ): Array<ColDef> => {
      const metricsDropdown: GridToolbarMultipleDropdown =
        toolbarConfig.findControl(ComponentNames.metrics);

      const dataset1Dropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.dataset1
      );

      const dataset2Dropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.dataset2
      );

      let gridColDefs: Array<ColDef> = [];
      if (metricsDropdown.selected?.length > 0) {
        if (
          dataset2Dropdown.selected.value === CustomOptions.None.toLowerCase()
        ) {
          gridColDefs = gridColDefs.concat(
            GridColDefs.Shared.getCommonColDef(toolbarConfig)
          );
          gridColDefs = gridColDefs.concat(
            GridColDefs.Shared.getDatasetColumnDef(
              periodColumnDefs,
              toolbarConfig,
              ''
            )
          );
        } else {
          gridColDefs = gridColDefs.concat([
            {
              headerName: '',
              headerClass: 'parent-header',
              children: GridColDefs.Shared.getCommonColDef(toolbarConfig),
            } as ColDef,
            {
              headerName: dataset1Dropdown.selected.text.toUpperCase(),
              headerClass: 'parent-header',
              children: GridColDefs.Shared.getDatasetColumnDef(
                periodColumnDefs,
                toolbarConfig,
                'Dataset1'
              ),
            } as ColDef,
            {
              headerName: dataset2Dropdown.selected.text.toUpperCase(),
              headerClass: 'parent-header',
              children: GridColDefs.Shared.getDatasetColumnDef(
                periodColumnDefs,
                toolbarConfig,
                'Dataset2',
                10
              ),
            } as ColDef,
            {
              headerName: 'VARIANCE',
              headerClass: 'parent-header',
              children: GridColDefs.Shared.getDatasetColumnDef(
                periodColumnDefs,
                toolbarConfig,
                undefined,
                20
              ),
            } as ColDef,
          ] as Array<ColDef>);
        }
      }

      return gridColDefs;
    },
    getCommonColDef: (toolbarConfig: GridToolbarConfig): Array<ColDef> => {
      const group1Dropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.group1
      );

      const group2Dropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.group2
      );

      const group3Dropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.group3
      );

      const commonColumns: Array<ColDef> = [
        {
          headerName: '',
          headerClass: 'parent-header',
          children:
            group1Dropdown.selected.value !== GroupsBy.ByRecord.id
              ? ([
                  {
                    headerName: group1Dropdown.selected.text.toUpperCase(),
                    colId: group1Dropdown.selected.value,
                    field: 'groupKey1',
                    pinned: true,
                    type: 'textColumn',
                    minWidth: 120,
                    width: 200,
                  },
                ] as Array<ColDef>)
              : [
                  {
                    headerName: 'RECORD TYPE',
                    colId: group1Dropdown.selected.value,
                    field: 'subGroupKey1',
                    pinned: true,
                    type: 'textColumn',
                    minWidth: 120,
                    width: 200,
                  },
                  {
                    headerName: 'RECORD NAME',
                    colId: group1Dropdown.selected.value,
                    field: 'subGroupKey2',
                    pinned: true,
                    type: 'textColumn',
                    minWidth: 120,
                    width: 200,
                  },
                  {
                    headerName: 'RECORD NUMBER',
                    colId: group1Dropdown.selected.value,
                    field: 'subGroupKey3',
                    pinned: true,
                    type: 'textColumn',
                    minWidth: 120,
                    width: 200,
                  },
                  {
                    headerName: 'REFERENCE CONTRACT/OPP',
                    colId: group1Dropdown.selected.value,
                    field: 'referenceId',
                    pinned: true,
                    type: 'textColumn',
                    minWidth: 120,
                    width: 200,
                  },
                ],
        } as ColDef,
      ] as Array<ColDef>;

      if (group2Dropdown.selected.value !== CustomOptions.None) {
        if (group2Dropdown.selected.value !== GroupsBy.ByRecord.id) {
          commonColumns[0]['children'].push({
            headerName: group2Dropdown.selected.text.toUpperCase(),
            colId: group2Dropdown.selected.value,
            field: 'groupKey2',
            pinned: true,
            type: 'textColumn',
            minWidth: 120,
            width: 200,
          });
        } else {
          commonColumns[0]['children'].push(
            {
              headerName: 'RECORD TYPE',
              colId: group2Dropdown.selected.value,
              field: 'subGroupKey1',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            },
            {
              headerName: 'RECORD NAME',
              colId: group2Dropdown.selected.value,
              field: 'subGroupKey2',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            },
            {
              headerName: 'RECORD NUMBER',
              colId: group2Dropdown.selected.value,
              field: 'subGroupKey3',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            },
            {
              headerName: 'REFERENCE CONTRACT/OPP',
              colId: group1Dropdown.selected.value,
              field: 'referenceId',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            }
          );
        }
      }

      if (group3Dropdown.selected.value !== CustomOptions.None) {
        if (group3Dropdown.selected.value !== GroupsBy.ByRecord.id) {
          commonColumns[0]['children'].push({
            headerName: group3Dropdown.selected.text.toUpperCase(),
            colId: group3Dropdown.selected.value,
            field: 'groupKey3',
            pinned: true,
            type: 'textColumn',
            minWidth: 120,
            width: 200,
            cellRendererParams: {
              cellClass: 'grid-border-right',
            },
          });
        } else {
          commonColumns[0]['children'].push(
            {
              headerName: 'RECORD TYPE',
              colId: group3Dropdown.selected.value,
              field: 'subGroupKey1',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            },
            {
              headerName: 'RECORD NAME',
              colId: group3Dropdown.selected.value,
              field: 'subGroupKey2',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            },
            {
              headerName: 'RECORD NUMBER',
              colId: group3Dropdown.selected.value,
              field: 'subGroupKey3',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            },
            {
              headerName: 'REFERENCE CONTRACT/OPP',
              colId: group1Dropdown.selected.value,
              field: 'referenceId',
              pinned: true,
              type: 'textColumn',
              minWidth: 120,
              width: 200,
            }
          );
        }
      }

      return commonColumns;
    },
    getDatasetColumnDef: (
      periodColumnDefs: Array<ColDef>,
      toolbarConfig: GridToolbarConfig,
      dataset?: string,
      additionalSort?: number
    ): Array<ColDef> => {
      const metricsDropdown: GridToolbarMultipleDropdown =
        toolbarConfig.findControl(ComponentNames.metrics);

      return metricsDropdown.selected.map((x: TextValuePair) => {
        const additionalField: string =
          x.value === MetricsValues.CciPercentage
            ? MetricsValues.CciPercentage
            : undefined;

        return {
          headerName: x.text.toUpperCase(),
          headerClass: 'parent-header',
          children: GridColDefs.Shared.getMetricsColumnDef(
            periodColumnDefs,
            `${x.value}${dataset}`,
            additionalField,
            additionalSort
          ),
        } as ColDef;
      });
    },
    getMetricsColumnDef: (
      periodColumnDefs: Array<ColDef>,
      metric: string,
      additionalField?: string,
      additionalSort?: number
    ): Array<ColDef> => {
      const formattedMetric: string = metric
        .replace('Dollar', '')
        .replace('Percentage', 'Percent')
        .replace('undefined', '');

      if (additionalField) {
        additionalField = additionalField
          .replace('Dollar', '')
          .replace('Percentage', 'Percent');
      }

      additionalField = additionalField ? `.${additionalField}` : '';
      additionalSort = additionalSort ?? 0;

      return periodColumnDefs.map((x: ColDef) => {
        const period: any = Object.assign({}, x);
        period.colId = `${formattedMetric}.${period.field}`;
        period.field = `${formattedMetric}.${period.field}${additionalField}`;
        period.type = metric.includes(MetricsValues.CciPercentage)
          ? 'percentColumn'
          : period.type;
        const sortedAt: number =
          GridFilters.Metrics.findIndex((x: TextValuePair) =>
            metric.startsWith(x.value)
          ) * 10;

        if (period.field.includes('total')) {
          period.sortedAt = sortedAt + additionalSort;
          period.sort = 'desc';
        }

        if (metric.includes(MetricsValues.CciPercentage)) {
          if (additionalSort && !metric.includes('Dataset')) {
            period.aggFunc = GridColDefs.Shared.sumCciPercentageCompare;
          } else {
            period.aggFunc = GridColDefs.Shared.sumCciPercentage;
          }
        } else {
          period.aggFunc = 'sum';
        }

        return period;
      });
    },
    addCustomRendererConfigs: (
      periodColDefs: Array<ColDef>,
      useFieldAsId: boolean
    ): Array<ColDef> => {
      periodColDefs = periodColDefs.map((x: ColDef) => ({
        ...x,
        colId: x.colId,
        minWidth: 65,
        field: useFieldAsId ? x.field : x.colId,
        type: 'numberColumn',
      }));

      return periodColDefs;
    },
    sumCciPercentage: (values: Array<number>, colDef?: ColDef): number => {
      let result = 0;

      if (colDef) {
        const totalCciAmount: number = sumBy(values, `${colDef.colId}.cci`);
        const totalRevenueAmount: number = sumBy(
          values,
          `${colDef.colId}.revenue`
        );

        if (typeof (totalCciAmount || totalRevenueAmount) === 'number') {
          result = (totalCciAmount / totalRevenueAmount || 0) * 100;
        }
      }

      return isFinite(result) ? result : 0;
    },
    sumCciPercentageCompare: (
      values: Array<number>,
      colDef?: ColDef
    ): number => {
      let result = 0;

      if (colDef) {
        const dataset1Cci: number = sumBy(
          values,
          `cciPercentDataset1.${colDef.colId.split('.')[1]}.cci`
        );
        const dataset1Revenue: number = sumBy(
          values,
          `cciPercentDataset1.${colDef.colId.split('.')[1]}.revenue`
        );
        const dataset2Cci: number = sumBy(
          values,
          `cciPercentDataset2.${colDef.colId.split('.')[1]}.cci`
        );
        const dataset2Revenue: number = sumBy(
          values,
          `cciPercentDataset2.${colDef.colId.split('.')[1]}.revenue`
        );

        if (
          typeof (
            dataset1Cci ||
            dataset1Revenue ||
            dataset2Cci ||
            dataset2Revenue
          ) === 'number'
        ) {
          if (
            typeof (
              dataset1Cci ||
              dataset1Revenue ||
              dataset2Cci ||
              dataset2Revenue
            ) === 'number'
          ) {
            let currentPercentage: number =
              dataset1Cci / Math.abs(dataset1Revenue) || 0;
            let targetPercentage: number =
              dataset2Cci / Math.abs(dataset2Revenue) || 0;
            currentPercentage = isFinite(currentPercentage)
              ? currentPercentage
              : 0;
            targetPercentage = isFinite(targetPercentage)
              ? targetPercentage
              : 0;

            result = (currentPercentage - targetPercentage) * 100;
          }
        }
      }

      return result;
    },
  },
  getExtraGridOptions: (): GridOptions => {
    return {
      defaultExportParams: {
        sheetName: 'Analytics',
        fileName: 'CBP Analytics',
      } as CsvExportParams,
    } as GridOptions;
  },
};
